<template>
  <b-overlay :show="isLoading">

    <b-card class="p-0">
      <div class="media">
        <div class="media-body">
          <span class="text-muted text-uppercase font-weight-bold">{{ title }}</span>
          <h3 class="mb-0">{{ value }}</h3>
          <small
            v-if="unit"
            class="font-size-12 font-weight-normal"
          >
            {{unit}}
          </small>
          
        </div>
        <div class="align-self-center">
          <feather
            v-if="color === 'primary'"
            :type="`${icon}`"
            class="icon-lg icon-dual icon-dual-primary"
          ></feather>
          <feather
            v-else-if="color === 'info'"
            :type="`${icon}`"
            class="icon-lg icon-dual icon-dual-info"
          ></feather>
          <feather
            v-else-if="color === 'success'"
            :type="`${icon}`"
            class="icon-lg icon-dual icon-dual-success"
          ></feather>
          <feather
            v-else-if="color === 'warning'"
            :type="`${icon}`"
            class="icon-lg icon-dual icon-dual-warning"
          ></feather>
          <feather
            v-else
            :type="`${icon}`"
            class="icon-lg icon-dual icon-dual-danger"
          ></feather>
        </div>
      </div>

      <div v-if="subs.length" class="pt-2">
        <a v-b-toggle="title" href="#" @click.prevent>
          <i class="uil uil-plus-circle"></i> ดูรายละเอียด
        </a>

        <b-collapse :id="title" class="pt-1">
          <b-row>
            <b-col
                v-for="(sub, index) in subs"
                :key="index"
                :cols="sub == '<hr>' ? 12 : 6"
            >
              <hr v-if="sub == '<hr>'"/>
              <span v-else-if="sub">{{ sub }}</span>
            </b-col>
          </b-row>
        </b-collapse>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '0',
    },
    icon: {
      type: String,
      default: 'activity',
    },
    color: {
      type: String,
      default: '',
    },
    unit: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    subs: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
